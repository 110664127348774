#root checkout-zero{
  @media #{$mobile}{
    .CheckoutZeroMainItem0{
      display: flex;
      flex-direction: column;
    }
    .CheckoutZeroPod0{
      &::-webkit-scrollbar{
        display: none;
      }
    }
    .CheckoutZeroPod1{
      margin:var(--wml-spacing7)  0 0 0;
    }
  }
}
