#root media-entity-main{
  @media #{$mobile}{
    .CardZeroView{
      &0,&1{
        width : 100%;
        flex: 1 0 100%;
      }
    }
    .WmlPanelView{
      right:-100%;
      width:100%;
    }
  }
}
