#root plans-zero{

  @media #{$mobile}{
    .SectionTwoPod0{
      padding:var(--wml-spacing7)
    }
    .SectionTwoPod0 > .SectionTwoPod0Item0:nth-child(1){
      display:none;
    }

    .SectionTwoPod0 > :not(.SectionTwoPod0Item0:nth-child(1)){
      display:flex;
      flex-direction: column;

    }

    .SectionTwoPod0 > .SectionTwoPod0Item0:nth-child(n+3){
      margin:var(--wml-spacing7) 0 0 0;

    }
  }
}


