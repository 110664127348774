#root projects-zero{
  .CardZeroView{
    height: 100%;
  }
  @media #{$tablet,$desktop}{
    .CardZeroViewProject{
      min-height: calc(480/16 * 1em);
    }
  }

  @media #{$tablet}{
    .CardZeroPod0 img{
      width: calc(300/16 * 1em);
    }
    .CardZeroMainItem0{
      max-height: 75%;
    }
  }

}
