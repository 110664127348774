@font-face {
  font-family: 'PT-Mono';
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url('../fonts/PT-Mono/PT-Mono.ttf') format('truetype'),
  url('../fonts/PT-Mono/PT-Mono.eot') format('embedded-opentype'),
  url('../fonts/PT-Mono/PT-Mono.svg') format('svg'),
  url('../fonts/PT-Mono/PT-Mono.woff') format('woff'),
  url('../fonts/PT-Mono/PT-Mono.woff2') format('woff2');
}

@font-face {
  font-family: 'Component-Italic';
  font-style: normal;

  font-weight: 400;
  font-display: block;
  src: url('../fonts/Component-Italic/Component.ttf') format('truetype'),
  url('../fonts/Component-Italic/Component.eot') format('embedded-opentype'),
  url('../fonts/Component-Italic/Component.svg') format('svg'),
  url('../fonts/Component-Italic/Component.woff') format('woff'),
  url('../fonts/Component-Italic/Component.woff2') format('woff2');

}
@font-face {
  font-family: 'Freelancer';
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url('../fonts/Freelancer/Freelancer.ttf') format('truetype'),
  url('../fonts/Freelancer/Freelancer.eot') format('embedded-opentype'),
  url('../fonts/Freelancer/Freelancer.svg') format('svg'),
  url('../fonts/Freelancer/Freelancer.woff') format('woff'),
  url('../fonts/Freelancer/Freelancer.woff2') format('woff2');

}

@font-face {
  font-family: 'Quicksand-Book';
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url('../fonts/Quicksand-Book/Quicksand-Book.ttf') format('truetype'),
  url('../fonts/Quicksand-Book/Quicksand-Book.eot') format('embedded-opentype'),
  url('../fonts/Quicksand-Book/Quicksand-Book.svg') format('svg'),
  url('../fonts/Quicksand-Book/Quicksand-Book.woff') format('woff'),
  url('../fonts/Quicksand-Book/Quicksand-Book.woff2') format('woff2');
}

$display-xx-large:calc(72/16 * 1rem);
$display-x-large:calc(64/16 * 1rem);
$display-large:calc(56/16 * 1rem);
$display:calc(40/16 * 1rem);
$display-small:calc(32/16 * 1rem);
$display-x-small:calc(28/16 * 1rem);
$display-xx-small:calc(24/16 * 1rem);


$body:calc(20/16 * 1rem);
$body-bold:calc(20/16 * 1rem);
$body-small:calc(16/16 * 1rem);
$body-small-bold:calc(16/16 * 1rem);

$button:calc(16/16 * 1rem);
$text:calc(16/16 * 1rem);

$overline:calc(18/16 * 1rem);
$overline-bold:calc(18/16 * 1rem);
$overline-small:calc(18/16 * 1rem);

$caption:calc(14/16 * 1rem);
$legal:calc(14/16 * 1rem);

$wml-h1:calc(40/16 * 1rem);
$wml-h2:calc(36/16 * 1rem);
$wml-h3:calc(32/16 * 1rem);
$wml-h4:calc(28/16 * 1rem);
$wml-h5:calc(24/16 * 1rem);
$wml-h6:calc(22/16 * 1rem);
$wml-p: calc(20/16 * 1rem);


:root {
  --display-xx-large: #{$display-xx-large};
  --display-x-large: #{$display-x-large};
  --display-large: #{$display-large};
  --display: #{$display};
  --display-small: #{$display-small};
  --display-x-small: #{$display-x-small};
  --display-xx-small: #{$display-xx-small};

  --body: #{$body};
  --body-bold: #{$body-bold};
  --body-small: #{$body-small};
  --body-small-bold: #{$body-small-bold};

  --button: #{$button};
  --text: #{$text};
  --overline: #{$overline};
  --overline-bold: #{$overline-bold};
  --overline-small: #{$overline-small};
  --caption: #{$caption};
  --legal: #{$legal};

  --wml-h1: #{$wml-h1};
  --wml-h2: #{$wml-h2};
  --wml-h3: #{$wml-h3};
  --wml-h4: #{$wml-h4};
  --wml-h5: #{$wml-h5};
  --wml-h6: #{$wml-h6};
  --wml-p: #{$wml-p};
}






