#root about-main{
  @media #{$mobile}{
    .SectionOneMainPod{
      @include WMLFlexColumnCenter
    }
    .SectionOnePod1{
      order: 1;
      flex:1 0 100%;
    }
    .SectionOnePod0,
    .SectionOnePod1{
      width: 100%;
      padding:var(--wml-spacing7);
      * {
        text-align: center;
      }
    }
    .SectionOnePod2{
      order: 2;
      justify-content: center;
      @include WMLFlexRowCenter
    }
  }
}
