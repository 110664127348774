#root account-projects-zero{
  @media #{$mobile}{
    .AccountProjectsZeroMainPod{
      margin:var(--wml-spacing5) 0 0 0;
    }
    .AccountProjectsZeroMainText0{
      flex-direction: column;
      justify-content: center;
      align-items: center;
      *{
        text-align: center;
      }
    }
    .trello-board{
      margin:var(--wml-spacing5) 0 0 0;
    }

    .AccountProjectsZeroPod0Item0{
      height:110vh;
    }
  }
}
