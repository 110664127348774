#root account-profile-zero{
  @media #{$mobile}{

    .AccountProfileZeroPod0Item0{
      margin:var(--wml-spacing3) 0 0 0;
      wml-form{
        .WmlFormPod0{
          &:nth-child(2){
            align-items: flex-start;
            flex-direction: column;
            .VerifiedZeroPod1{
              margin: 0 0 0 var(--wml-spacing3);

            }
            .WmlFormPod0Item0 {
              width: 100%;
            }

          }
          @for $i from 1 through 3 {
            &:nth-child(#{$i}) {
              .WmlFormPod0Item0 {
                padding: 0 var(--wml-spacing3);
                flex: 0 0 100%;
              }
            }
          }
        }
      }
    }

  }
}
