#root account-zero{
  @media #{$mobile}{
    .AccountZeroMainItem0{
      flex-direction: column;
    }

    .AccountZeroMainItem1{
      flex: 0 1 auto;
    }
    .AccountZeroMainItem2{
      padding:0
    }
    .AccountZeroPod1{
      display: none;
    }

    .AccountDashboardZeroPod0 >*,.AccountDashboardZeroPod1 >*{
      padding:var(--wml-spacing5) 0;
    }

    .AccountDashboardZeroPod0Text0{
      font-size: var(--display-x-small);
      overflow:hidden;
      text-overflow: ellipsis;
    }

    .AccountDashboardZeroPod0Item1{
      max-width: 100%;
    }

    .AccountDashboardZeroMainPod{
      display: block;
    }
  }

  @media only screen and (max-width: 1006px){
      .AccountDashboardZeroMainPod{
        display: block;
      }
  }
}
