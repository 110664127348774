#root projects-zero{
  @media #{$mobile}{
    .ProjectsZeroMainItem0:not(:nth-of-type(1)){
      margin:var(--wml-spacing15) 0 0 0;
    }
    .CarouselZeroPod1 .CardZeroMainItem1,
    .CarouselZeroPod1 .CardZeroPod1
    {
      display:none;
    }
    .CardZeroViewProject{
      height: calc(325/16 * 1em);
    }

    .CardZeroViewProject img{
      height: auto;
      width: calc(150/16 * 1rem);
    }

    .CardZeroMainItem0{
      max-height: 75%;
      flex-direction: column;

    }

    .CardZeroPod1{
      padding: var(--wml-spacing3) var(--wml-spacing5);

    }

    .CardZeroMainImg0{
      flex: 0 0 35%;
      justify-content: center;
      display: flex;
      img{
        width: auto;
      }
    }

    .CarouselZeroPod2{
      margin: calc(224/16* 1rem) 0 0 0;
      .CardZeroMainPod{
        height: calc(300/10.6 * 1em);
      }

      .CardZeroMainItem0{
        max-height: none;
        overflow: hidden auto;
        &::-webkit-scrollbar{
          display: none;
        }
      }

      .CardZeroPod0 img{
        width: calc(360/16 * 1em);
      }

      .CardZeroPod1{
        overflow: visible;
      }

      .CardZeroMainItem1{
        flex-flow:row wrap;
      }

      .CardZeroMainImg0{
        margin: var(--wml-spacing3) 0 0 0;
        flex: 0 0 50%;
      }

    }
  }
}


