#root  wml-mobile-nav-zero{
  .WmlMobileNavZeroPod0{
    background: var(--wml-orig-gradient-0);
    flex:0 0 45% ;
  }

  .MobileNavLogoDisplay{
    background-color: rgba(var(--wml-orig-black));
    padding: var(--wml-spacing7) ;
    @include WMLFlexColumnCenter;

    .LogoDisplayZeroMainPod{
      @include WMLFlexColumnCenter;
      padding:var(--wml-spacing3) ;
      background: var(--wml-orig-gradient-0) ;
    }

  }
}

