@use "sass:string";

@function to-number($value) {
  @if type-of($value) == "number" {
    @return $value;
  } @else if type-of($value) != "string" {
    $_: log("Value for `to-number` should be a number or a string.");
  }

  $result: 0;
  $digits: 0;
  $minus: str-slice($value, 1, 1) == "-";
  $numbers: (
    "0": 0,
    "1": 1,
    "2": 2,
    "3": 3,
    "4": 4,
    "5": 5,
    "6": 6,
    "7": 7,
    "8": 8,
    "9": 9
  );

  @for $i from if($minus, 2, 1) through str-length($value) {
    $character: str-slice($value, $i, $i);

    @if not(index(map-keys($numbers), $character) or $character == ".") {
      @return to-length(if($minus, -$result, $result), str-slice($value, $i));
    }

    @if $character == "." {
      $digits: 1;
    } @else if $digits == 0 {
      $result: $result * 10 + map-get($numbers, $character);
    } @else {
      $digits: $digits * 10;
      $result: $result + map-get($numbers, $character) / $digits;
    }
  }

  @return if($minus, -$result, $result);
}

@function provideRandomColor() {
  $result: rgba(random(255), random(255), random(255), random(1));
  @return $result;
}

@function contrastColor($color, $dark, $light) {
  $result: if(lightness($color) < 45%, $light, $dark);

  @debug "#{$result}";
  @return var($result)
}

@function hex-to-filter($hex-color) {
  $r: red($hex-color);
  $g: green($hex-color);
  $b: blue($hex-color);
  $filter:  invert(100%) rgba(#{$r}, #{$g}, #{$b}, 1);
  @return $filter;
}

@function convert-to-rgb($color) {
  $channels: string.split($color, ",");
  @return rgb(to-number(nth($channels, 1)), to-number(nth($channels, 2)), to-number(nth($channels, 3)));
}


$wml-white: "255,255,255";
$wml-black: "0,0,0";
$wml-primary: "144,238,144";
$wml-secondary: "112,112,112";
$wml-tertiary: "128,0,0";
$wml-star-selected: "255,215,0";
$wml-alert: "255,0,0";
$wml-gradient-0: radial-gradient(farthest-corner at 100% 0px,grey 0%,convert-to-rgb($wml-black) 150%);
$wml-gradient-1: radial-gradient(farthest-corner at 100% 0px,convert-to-rgb($wml-primary) 0%,convert-to-rgb($wml-secondary) 150%);
$wml-nav-color:$wml-black;
$wml-nav-color-dark:$wml-primary;
$wml-input-background-color-light-0: "211, 211, 211";
$wml-input-background-color-dark-0: "59,59,59";
$wml-google-primary:$wml-white;
$wml-facebook-primary: "58,89,152";
$wml-twitter-primary: "29,161,242";
$wml-yahoo-primary: "67,2,151";
$wml-apple-primary:$wml-black;
$wml-microsoft-primary: "127,186,0";
$wml-github-primary: "24,23,23";
$wml-popup-background:"112,112,112";

$wml-success-dark:"132,225,188";
$wml-success-light:"1,71,55";

:root {

    --wml-input-background-color-light-0:#{$wml-input-background-color-light-0};
    --wml-orig-input-background-color-light-0:#{$wml-input-background-color-light-0};
    --wml-input-background-color-dark-0:#{$wml-input-background-color-dark-0};
    --wml-orig-input-background-color-dark-0:#{$wml-input-background-color-dark-0};


    --wml-white:#{$wml-white};
    --wml-orig-white:#{$wml-white};
    --wml-black:#{$wml-black};
    --wml-orig-black:#{$wml-black};
    --wml-primary:#{$wml-primary};
    --wml-orig-primary:#{$wml-primary};
    --wml-secondary:#{$wml-secondary};
    --wml-star-selected:#{$wml-star-selected};
    --wml-alert:#{$wml-alert};
    --wml-tertiary:#{$wml-tertiary};
    --wml-gradient-0:#{$wml-gradient-0};
    --wml-gradient-1:#{$wml-gradient-1};
    --wml-orig-gradient-1:#{$wml-gradient-1};
    --wml-orig-secondary:#{$wml-secondary};
    --wml-orig-star-selected:#{$wml-star-selected};
    --wml-orig-alert:#{$wml-alert};
    --wml-orig-tertiary:#{$wml-tertiary};
    --wml-orig-gradient-0:#{$wml-gradient-0};
    --wml-gradient-1:#{$wml-gradient-1};
    --wml-orig-gradient-1:#{$wml-gradient-1};

    --wml-nav-color:#{$wml-nav-color};
    --wml-orig-nav-color:#{$wml-nav-color};

    --wml-popup-background:#{$wml-popup-background};
    --wml-orig-popup-background:#{$wml-popup-background};

    --wml-google-primary: #{$wml-google-primary};
    --wml-facebook-primary: #{$wml-facebook-primary};
    --wml-twitter-primary: #{$wml-twitter-primary};
    --wml-yahoo-primary: #{$wml-yahoo-primary};
    --wml-apple-primary: #{$wml-apple-primary};
    --wml-microsoft-primary: #{$wml-microsoft-primary};
    --wml-github-primary: #{$wml-github-primary};

    
    --wml-success-dark:#{$wml-success-dark};
    --wml-orig-success-dark:#{$wml-success-dark};
    --wml-success-light:#{$wml-success-light};
    --wml-orig-success-light:#{$wml-success-light};
}



