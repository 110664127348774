#root sign-up-sign-in-main{
  .SignUpSignInMainPod0Btn0{
    &Btn{
      width: 100%;
      padding-top: var(--wml-spacing5) ;
      padding-bottom: var(--wml-spacing5) ;
    }
    &Google {
      background: rgba(var(--wml-google-primary));
      *{
        color:rgba(var(--wml-orig-black))
      }
      &:hover{
        .WmlButtonZeroMainButton0Text0{
          color: rgba(var(--wml-orig-black))
        }
      }
    }
    &Facebook {
      background: rgba(var(--wml-facebook-primary));
    }
    &Twitter {
      background: rgba(var(--wml-twitter-primary));
    }
    &Yahoo {
      background: rgba(var(--wml-yahoo-primary));
    }
    &Microsoft {
      background: rgba(var(--wml-microsoft-primary));
    }
    &Github {
      background: rgba(var(--wml-github-primary));
    }
    &Apple {
      background: rgba(var(--wml-apple-primary));
    }

  }

}
