@import "node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss";

@import "node_modules/prismjs/themes/prism-solarizedlight.min.css";
@import "node_modules/pikaday/scss/pikaday.scss";
@import "assets/styles/static.css";


@import "src/assets/styles/material-cdk-overlay.scss";
@import "src/assets/styles/global.scss";
@import "src/assets/styles/common.scss";
@import "src/assets/styles/media-queries.scss";
@import "src/assets/styles/layouts.scss";
@import "src/assets/styles/css.scss";



// misc
@import "src/app/core/not-found/not-found.component.phone.scss";
@import "src/app/app.component.global.scss";

// default layouts
@import "src/app/layouts/default/default-layout/default-layout.component.phone.scss";
@import "src/app/layouts/default/default-layout/default-layout.component.global.scss";

// nav-zero
@import "src/app/shared/components/nav-zero/nav-zero.component.global.scss";
@import "src/app/shared/components/nav-zero/nav-zero.component.phone.scss";

// home-main
@import "src/app/pages/home/home-main/home-main.component.phone.scss";

// about-main
@import "src/app/pages/about/about-main/about-main.component.phone.scss";

// projects-zero
@import "src/app/pages/projects-zero/projects-zero/projects-zero.component.global.scss";
@import "src/app/pages/projects-zero/projects-zero/projects-zero.component.phone.scss";

// media-entity-main
@import "src/app/pages/media-entity/media-entity-main/media-entity-main.component.phone.scss";
@import "src/app/pages/media-entity/media-entity-main/media-entity-main.component.global.scss";

// order-confirmed
@import "src/app/pages/order-confirmed/order-confirmed-main/order-confirmed-main.component.phone.scss";


// rating-carousel
@import "src/app/shared/components/rating-carousel/rating-carousel.component.phone.scss";

// rating-card
@import "src/app/shared/components/rating-card/rating-card.component.phone.scss";

// contact us form
@import "src/app/shared/components/contact-us-form/contact-us-form.component.global.scss";
@import "src/app/shared/components/contact-us-form/contact-us-form.component.phone.scss";

// contact main
@import "src/app/pages/contact/contact-main/contact-main.component.phone.scss";


// footer-zero
@import "src/app/shared/components/footer-zero/footer-zero.component.phone.scss";




// sign-up-sign-in
@import "src/app/pages/sign-up-sign-in/sign-up-sign-in-main/sign-up-sign-in-main.component.global.scss";
@import "src/app/pages/sign-up-sign-in/sign-up-sign-in-main/sign-up-sign-in-main.component.phone.scss";

// account-zero
@import "src/app/pages/account-zero/account-zero/account-zero.component.phone.scss";
@import "src/app/pages/account-zero/account-zero/account-zero.component.global.scss";

// card-one
@import "src/app/shared/components/card-one/card-one.component.phone.scss";
@import "src/app/shared/components/card-one/card-one.component.global.scss";

// account-dashboard-zero
@import "src/app/pages/account-dashboard-zero/account-dashboard-zero/account-dashboard-zero.component.phone.scss";
@import "src/app/pages/account-dashboard-zero/account-dashboard-zero/account-dashboard-zero.component.global.scss";


// account-billing-zero
@import "src/app/pages/account-billing-zero/account-billing-zero/account-billing-zero.component.phone.scss";
@import "src/app/pages/account-billing-zero/account-billing-zero/account-billing-zero.component.global.scss";

// carousel-zero
@import "src/app/shared/components/carousel-zero/carousel-zero.component.phone.scss";
@import "src/app/shared/components/carousel-zero/carousel-zero.component.global.scss";

// card-two
@import "src/app/shared/components/card-two/card-two.component.phone.scss";
@import "src/app/shared/components/card-two/card-two.component.global.scss";

// confirm-dialog-zero
@import "src/app/shared/components/confirm-dialog-zero/confirm-dialog-zero.component.global.scss";
@import "src/app/shared/components/confirm-dialog-zero/confirm-dialog-zero.component.phone.scss";


// dashboard-ctrl-zero
@import "src/app/shared/components/dashboard-ctrl-zero/dashboard-ctrl-zero.component.global.scss";
@import "src/app/shared/components/dashboard-ctrl-zero/dashboard-ctrl-zero.component.phone.scss";

// account-manage-your-data-zero
@import "src/app/pages/account-manage-your-data-zero/account-manage-your-data-zero/account-manage-your-data-zero.component.global.scss";
@import "src/app/pages/account-manage-your-data-zero/account-manage-your-data-zero/account-manage-your-data-zero.component.phone.scss";





// plans-zero
@import "src/app/pages/plans-zero/plans-zero/plans-zero.component.global.scss";
@import "src/app/pages/plans-zero/plans-zero/plans-zero.component.phone.scss";

// section-two
@import "src/app/shared/components/section-two/section-two.component.global.scss";
@import "src/app/shared/components/section-two/section-two.component.phone.scss";

// products-zero
@import "src/app/pages/products-zero/products-zero/products-zero.component.global.scss";
@import "src/app/pages/products-zero/products-zero/products-zero.component.phone.scss";

// product-detail-zero
@import "src/app/pages/product-detail-zero/product-detail-zero/product-detail-zero.component.global.scss";
@import "src/app/pages/product-detail-zero/product-detail-zero/product-detail-zero.component.phone.scss";

// color-option-zero
@import "src/app/shared/components/color-option-zero/color-option-zero.component.global.scss";
@import "src/app/shared/components/color-option-zero/color-option-zero.component.phone.scss";

// panel-cart-zero
@import "src/app/shared/components/panel-cart-zero/panel-cart-zero.component.global.scss";
@import "src/app/shared/components/panel-cart-zero/panel-cart-zero.component.phone.scss";

// panel-cart-container-zero
@import "src/app/shared/components/panel-cart-container-zero/panel-cart-container-zero.component.global.scss";
@import "src/app/shared/components/panel-cart-container-zero/panel-cart-container-zero.component.phone.scss";

// checkout-zero
@import "src/app/pages/checkout-zero/checkout-zero/checkout-zero.component.global.scss";
@import "src/app/pages/checkout-zero/checkout-zero/checkout-zero.component.phone.scss";

// date-picker-zero
@import "src/app/shared/components/date-picker-zero/date-picker-zero.component.global.scss";
@import "src/app/shared/components/date-picker-zero/date-picker-zero.component.phone.scss";

// card-expiry-input-zero
@import "src/app/shared/components/card-expiry-input-zero/card-expiry-input-zero.component.global.scss";
@import "src/app/shared/components/card-expiry-input-zero/card-expiry-input-zero.component.phone.scss";

// card-number-input-zero
@import "src/app/shared/components/card-number-input-zero/card-number-input-zero.component.global.scss";
@import "src/app/shared/components/card-number-input-zero/card-number-input-zero.component.phone.scss";


// account-profile-zero
@import "src/app/pages/account-profile-zero/account-profile-zero/account-profile-zero.component.global.scss";
@import "src/app/pages/account-profile-zero/account-profile-zero/account-profile-zero.component.phone.scss";

// verified-zero
@import "src/app/shared/components/verified-zero/verified-zero.component.global.scss";
@import "src/app/shared/components/verified-zero/verified-zero.component.phone.scss";

// lab-viewer-zero
@import "src/app/shared/components/lab-viewer-zero/lab-viewer-zero.component.global.scss";
@import "src/app/shared/components/lab-viewer-zero/lab-viewer-zero.component.phone.scss";

// image-upload-zero
@import "src/app/shared/components/image-upload-zero/image-upload-zero.component.global.scss";
@import "src/app/shared/components/image-upload-zero/image-upload-zero.component.phone.scss";

// account-projects-zero
@import "src/app/pages/account-projects-zero/account-projects-zero/account-projects-zero.component.global.scss";
@import "src/app/pages/account-projects-zero/account-projects-zero/account-projects-zero.component.phone.scss";

// media-query-marker (IMPT DONT DELETE THIS LINE)


:root {

  @function provideRandomColor() {
    $result: rgba(random(255), random(255), random(255), random(1));

    @return $result;
  }

  @function contrastColor($color, $dark, $light) {
    $result: if(lightness($color) < 45%, $light, $dark);

    @debug "#{$result}";
    @return var($result)
  }

  @function prefixClass($class) {
    @return ""+$class;
  }

  @function hex-to-filter($hex-color) {
    $r: red($hex
    -color);
    $g: green($hex-color);
    $b: blue($hex-color);
    $filter:  invert(100%) rgba(#{$r}, #{$g}, #{$b}, 1);
    @return $filter;
  }



  // overflow: hidden;
  font-size: 10.6px;
  // previous size was 16
  color: rgba(var(--wml-white));


  @media #{$mobile}{
    font-size: calc(2vw);
  }

  @media #{$mobileFont}{
    font-size: calc(3vw);
  }



  @keyframes GlobalImg0Sping {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }


  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    z-index:5;
    &:not(i.fa-solid){
      font-family: 'Poppins', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    }
  }

  &:not(h1,h2,h3,h4,h5,h6,span,p){
    // transition: all 1s;
  }

  h1,h2,h3,h4,h5,h6,span,p{
    transition: none;
    color: rgba(var(--wml-black))
  }

  a {
    color: rgba(var(--wml-black));
  }


  input:not([type='checkbox']),
  select,
  textarea {
    width: 100%;
  }

  input:not([type='checkbox']){

    word-wrap: break-word;
    display: block;
    font-size: inherit;
    border-radius: calc(6/16 * 1rem);

    outline: none;
    margin: 0;
    padding: 0;
    transition: all 1s;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;

    width: 100%;
  }

  textarea{

    font-size: inherit;
    border-radius: calc(6/16 * 1rem);
    outline: none;
    border: calc(2/16 * 1rem) inset rgb(118,118,118);
    padding: var(--wml-spacing4);
    display: block;
    margin: 0;
    min-height: calc(40/16 * 1rem);
    height: calc(140/16 * 1rem);
    transition: all 1s;
    width: 100%;
    resize: none;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  }

  select {
    appearance: none;
  }

  li {
    list-style: none;
  }



  img{
    width: 100%;
    height: auto;
  }

  button {
    background-color: transparent;
  }

  html,body{
    background-color: var(--wml-default-layout-background);
  }

  mat-slide-toggle{
    label{
      color: rgba(var(--wml-white));
    }
  }

  h1{
    font-size:var(--wml-h1);
  }
  h2{
    font-size:var(--wml-h2);
  }
  h3{
    font-size:var(--wml-h3);
  }
  h4{
    font-size:var(--wml-h4);
  }
  h5{
    font-size:var(--wml-h5);
  }
  h6{
    font-size:var(--wml-h6);
  }
  p{
    font-size:var(--wml-p);
  }

}

