#root account-profile-zero{
  .AccountProfileZeroPod0Item0{
    wml-form{
      .WmlFormPod0{
        &:nth-child(1){
          display: flex;
          .WmlFormPod0Item0{
            padding:0 var(--wml-spacing3);
            flex: 0 0 50%;
          }
        }
        &:nth-child(2){
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          .WmlFormPod0Item0{
            padding:0 var(--wml-spacing3);
            flex: 0 0 50%;
          }
        }
        &:nth-child(3){
          display: flex;
          .WmlFormPod0Item0{
            padding:0 var(--wml-spacing3);
            flex: 0 0 30%;
          }
        }
      }
    }
  }
}
