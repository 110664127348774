
$wml-animation-duration-0:1s;
$wml-border-radius0:calc(8/16 * 1rem);
$wml-border-radius1:calc(10/16 * 1rem);
$wml-border-radius2:calc(20/16 * 1rem);
$wml-app-transition-time0:1s;


@mixin GlobalStrikeThru0 {
  border-top: calc(4 / 16 * 1rem) solid rgba(var(--wml-white));
  width: 100%;
}

@mixin cursorPointer0{
  &:hover{
    cursor: pointer;
  }
}


@mixin outline1 {
  border: calc(1/16 * 1rem)solid red;
}

@mixin outline2 {
  border: calc(1/16 * 1rem)solid blue;
}

@mixin outline3 {
  border: calc(1/16 * 1rem)solid green;
}

@mixin outline4 {
  border: calc(1/16 * 1rem)dotted white;
}


@mixin borderRadius0{
  border-radius: $wml-border-radius0;
}

@mixin borderRadius2{
  border-radius: $wml-border-radius2;
}

@mixin appOutline0{
  border: calc(1/16 * 1rem) solid $dark-grey;
}

@mixin wmlHighlightedTitle0{
  padding:var(--wml-spacing5);
  border-radius: $wml-border-radius0;
}



@keyframes toggle-from-left{
  0%{
    left:-100%;

  }
  100%{
    left:0%;
  }
}

@keyframes toggle-from-right{
  0%{
    left:100%;
  }
  100%{
    left:0%;
  }
}



@mixin FontFamily0{
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}
