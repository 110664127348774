#root default-layout{
  .DefaultLayoutPod1{
    background: var(--wml-gradient-1);
    router-outlet + * {
      flex: 1 0 auto;

    }
    // footer-zero{
    //   justify-self: flex-end;
    // }
  }
}
