#root account-billing-zero{
  .AccountBillingZeroPod1{
    wml-form{
      .WmlFormMainPod{
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
      }
      .WmlFormPod0{
        @for $i from 1 through 3 {
          &:nth-child(#{$i})  {
            flex: 1 0 100%;
            display: flex;
            justify-content: space-between;
            .WmlFormPod0Item0:not(:first-child){
              padding:0 0 0 var(--wml-spacing5);
            }
          }
        }

        &:nth-child(1){

          .WmlFormPod0Item0:nth-child(1){
            flex: 0 0 70%;
          }
          .WmlFormPod0Item0:nth-child(2){
            flex: 0 0 30%;
          }
        }
        &:nth-child(2){
          .WmlFormPod0Item0:nth-child(1){
            flex: 0 0 50%;
          }
          .WmlFormPod0Item0:nth-child(2){
            flex: 0 0 25%;
          }
        }
        &:nth-child(3){
          .WmlFormPod0Item0:nth-child(1){
            flex: 0 0 20%;
          }
          .WmlFormPod0Item0:nth-child(2){
            flex: 0 0 25%;
          }
        }

      }
    }
  }
  .AccountBillingZeroPod0Item0{
    wml-form{
      max-width: 90%;
      .WmlFormPod0{
        &:nth-child(1){
          width: 100%;
          display: flex;
          flex-flow: row wrap;
          justify-content: space-between;

          .WmlFormPod0Item0:nth-child(1){
            flex: 0 0 50%;
          }
          .WmlFormPod0Item0:nth-child(2){
            flex: 0 0 20%;
          }
          .WmlFormPod0Item0:nth-child(3){
            flex: 0 0 20%;
          }
        }
      }
    }
  }
}
