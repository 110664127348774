#root plans-zero{


  .PlansZeroPod0Title0{
    font-size: var(--display-small);
  }

  wml-accordion-zero-item{
    flex: 1 0 100%;
  }

  .WMLAccordionZeroItemPod0{
    background: var(--wml-gradient-1);
  }

  .WMLAccordionZeroItemPod0Icon0{
    color:rgba(var(--wml-black));
  }
}
