:root{
  --wml-border-radius0:#{$wml-border-radius0};
  --wml-border-radius1:#{$wml-border-radius1};
  --wml-border-radius2:#{$wml-border-radius2};
  --wml-app-transition-time0:#{$wml-app-transition-time0};
}

.rating-star {
  &-unselected {
    border-bottom: 0.7rem solid rgba(var(--wml-tertiary));
    &:before {
      border-bottom: 0.8rem solid rgba(var(--wml-tertiary));
    }
    &:after {
      border-bottom: 0.7rem solid rgba(var(--wml-tertiary));
    }
  }

  &-selected {
    border-bottom: 0.7rem solid rgba(var(--wml-star-selected));
    &:before {
      border-bottom: 0.8rem solid rgba(var(--wml-star-selected));
    }
    &:after {
      border-bottom: 0.7rem solid rgba(var(--wml-star-selected));
    }
  }

  &-unselected,
  &-selected {
    font-size: 10rem;
    position: relative;
    display: block;
    width: 0px;
    height: 0px;
    border-right: 1rem solid transparent;
    border-left: 1rem solid transparent;
    transform: rotate(35deg);
  }

  &-unselected:before,
  &-selected:before {
    border-left: 0.3rem solid transparent;
    border-right: 0.3rem solid transparent;
    position: absolute;
    height: 0;
    width: 0;
    top: -0.45rem;
    left: -0.65rem;
    display: block;
    content: "";
    transform: rotate(-35deg);
  }

  &-unselected:after,
  &-selected:after {
    position: absolute;
    display: block;
    top: 0.03rem;
    left: -1.05rem;
    width: 0;
    height: 0;
    border-right: 1rem solid transparent;
    border-left: 1rem solid transparent;
    transform: rotate(-70deg);
    content: "";
  }
}


.glassmorphism {
  border-radius: calc(30/10.6 * 1rem);
  -webkit-backdrop-filter: blur(calc(4/10.6 * 1rem));
  backdrop-filter: blur(calc(4/10.6 * 1rem));
  box-shadow: 0 calc(8/10.6 * 1rem) calc(32/10.6 * 1rem) 0 rgba(0,0,0,0.4);
  border: calc(1/10.6 * 1rem) solid #ffffff2e;
}



#root{

  .WmlSampleInfiniteDropdownItemMainItem1{
    border-bottom: 0.125rem solid rgba(var(--wml-secondary));
  }

  .StoreServiceView{

    @media #{$mobile}{
      right:-100%;
      width:100%;
    }
  }

  :not(wml-select-zero){
    .WmlSampleInfiniteDropdownItemMainItem1,.WmlTableZeroDropdownItemMainPod{
      white-space: nowrap;
      color: rgba(var(--wml-nav-color));
      background-color: var(--wml-gradient-1);
    }

  }

  wml-form{
    wml-input{
      input,textarea{
        background-color: rgba(var(--wml-input-background-color-light-0));
        font-size: var(--body);
      }
    }
    button-one{
      justify-self: flex-end;
    }

    .WmlFormPod0{
      input,textarea{
        padding: var(--wml-spacing3);
      }

    }
  }



}


:root {
  --mobile-font: #{$mobileFont};
  --mobile: #{$mobile};
  --small-mobile: #{$smallMobile};
  --medium-mobile: #{$mediumMobile};
  --tablet: #{$tablet};
  --tablet-portrait: #{$tabletPortrait};
  --tablet-portrait-down: #{$tabletPortraitDown};
  --tablet-landscape: #{$tabletLandscape};
  --small-desktop: #{$smallDesktop};
  --tablet-landscape-down: #{$tabletLandscapeDown};
  --desktop: #{$desktop};
  --not-mobile: #{$notMobile};
  --laptop: #{$laptop};
  --laptop-down: #{$laptopDown};
  --laptop-tablet: #{$laptopTablet};
  --laptop-tablet-landscape: #{$laptopTabletLandscape};
  --large-desktop: #{$largeDesktop};
  --large-desktop-down: #{$largeDesktopDown};
  --xl-desktop: #{$xlDesktop};
  --xl-desktop-down: #{$xlDesktopDown};
  --xxl-desktop: #{$xxlDesktop};
  --xxxl-desktop: #{$xxxlDesktop};
  --dekstop-down-for-campaigns: #{$dekstopDownForCampaigns};
  --tablet-for-header: #{$tabletForHeader};
  --tablet-landscape-for-header: #{$tabletLandscapeForHeader};
  --tablet-landscape-down-for-header: #{$tabletLandscapeDownForHeader};
  --desktop-for-header: #{$desktopForHeader};
  --laptop-for-header: #{$laptopForHeader};
}

