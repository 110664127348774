#root default-layout{
  @media #{$mobile}{
    .NavZeroPod1{
      display:none
    }
    .NavZeroPod0Icon0{
      display: block;
    }
  }
}
