#root contact-us-form{
  @media #{$mobile}{
    wml-form{
      button-one{
        justify-self: center;
      }
      .WmlFormMainPod{
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
      }
      .WmlFormPod0{
        input,textarea{
          padding: var(--wml-spacing3);
        }
        padding:0 !important;
        &:nth-child(1),&:nth-child(2){
          flex: 0 0 100%;
        }
        &:nth-child(3){
          flex:1 0 100%;
        }
      }
    }
  }
}
