#root product-detail-zero{
  .WmlLabelMainPodLabelText0{
    color: rgba(var(--wml-white))
  }
  .WmlFormPod0Item0:first-child wml-input input{
    width: calc(110/10.6 * 1rem);
  }
  wml-input  {
    input{
      padding:var(--wml-spacing3);
      font-size: var(--display-small);
    }
  }
  .RatingStarsMainPod{
    justify-content: flex-start;
  }
}
