#root account-zero{
  .AccountZeroPod0Img0  .ImageUploadZeroMainPod{
    aspect-ratio: 1 / 1;
  }
  .AccountZeroPod0Img0  .ImageUploadZeroPod0{
    border: calc(2/10.6 * 1rem) solid rgba(var(--wml-black));
    border-radius: 100%;
    aspect-ratio: 1 / 1;
    overflow: hidden;
  }
}
